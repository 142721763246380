
import { defineComponent } from "vue";

import { getCurrentUser, setCurrentUser} from "@/utils/index"
import { getDashboardData } from "@/api/dashboard"
import AccountList from "@/components/dashboard/AccountList.vue"

export default defineComponent({
  name: "account-details",
  components: {
    AccountList
  },
  data() {
    return {
      currentUsid: "",
      user: "",
      dashboarddata: {},
      oviRankImage: "",
      rankPrimeImage : "",
      showRankImage : true,
      loadingBalance: true,
      rankPrimeImageStyle : "",
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      pt : "",
      isBiboPremium : false
    }
  },
  setup() {  
    return { 
       
    };
  },
  methods: {
    async getDashboardData() {
      const response = await getDashboardData();

        if(response.data !== undefined ) {
           this.dashboarddata = response.data; 
          this.oviRankImage = "media/ovi_ranks/" + response.data.oviRank + ".png";

          if(response.data.rankPrime ==  'SEP' || response.data.rankPrime == 'GEP' || response.data.rankPrime == 'GAP'){
            this.rankPrimeImage = "media/rank_logos/" + response.data.rankPrime + "_header.png";
          } else {
            if(response.data.currentRank != "DI"){
              this.rankPrimeImage = "media/rank_logos/" + response.data.currentRank + "_header.png";
            } else {
              this.showRankImage = false;
            }
          }

          this.isBiboPremium = response.data.isBiboPremium;
        }
        if(response.data.isOviSealProformActive) {
            let allowChangeName = false;
            
            if(response.data.firstCoverage !== undefined && response.data.firstCoverage !== null) {
              if(response.data.firstCoverage.coverage_transtype === 'IMMEDIATE') {
                  allowChangeName = true;
              }
              
            }
        }
        this.loadingBalance = false;
    },
    onResize() {
      //this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      console.log(this.windowWidth);
      if(this.windowWidth <= 450){
        this.rankPrimeImageStyle = "height: 60px; width: 60px; margin-top: 50px; margin-left: -10px; position: absolute;";
      } else {
        this.rankPrimeImageStyle = "height:80px;width:80px;margin-top:110px;margin-left:-160px;position:absolute;";
      }
    }

    
  },
  mounted() {

      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });

      this.user = getCurrentUser();
      this.currentUsid = this.user;
      this.getDashboardData(); 
      this.pt = String(localStorage.getItem("pt"));
  }
});
