
import { computed, defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

import {getcountries, getcities, getjobclassifications}  from "@/api/common";
import { confirm_register, save_register}  from "@/api/register";


import ConfirmRegister from "@/views/auth/ConfirmRegister.vue";

interface Step1 { 
  insured_firstName: string;
  insured_middleName: string;
  insured_lastName: string;
  insured_birthday: string;
  username: string;
  password: string;
  cpassword: string;
  sponsorid: string;
  uplineid: string;
  position: string;
}

interface Step2 { 
  

  insured_mobileNumber: string;
  insured_email: string;  
  address_blockAndStreet: string;
  address_nameOfBuilding: string;
  address_unitNumber:  string;
  address_city:  string;
  address_postalCode:  string;
  address_country:  string;
  address_country2: string;
  job_classification:string;
  address_province:  string; 
  beneficiary_firstName: string;
  beneficiary_middleName: string;
  beneficiary_lastName: string;
  beneficiary_birthday: string;
  beneficiary_contact: string;
  beneficiary_relationship: string; 
  istncchecked : boolean;
}

interface Step3 {
  userid: string;
  securitypin: string;
  verifyingdata: boolean; 
  country_text: string;
  country_text2: string;
  job_classification_text: string;
  province_text: string;
  responsedata: object;
}

interface Step4 {
}

interface KTCreateApp extends Step1, Step2, Step3, Step4 {}

export default defineComponent({
  name: "create-account-modal",
  data() {
    return {   
        address_country: "",
        address_country2:"",
        job_classification:"",
        confirm_data: {},
        show_parent_consent : false,
        showContinueButton : false,
        istncchecked : false
    }
  },
  components: {
    Field,
    ErrorMessage,
    ConfirmRegister
  },
  props: { 
        slotdata: Object
  },
  setup(props, context) {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const countries = ref([]) as any; 
    const cities = ref([]) as any; 
    const job_classification_options_list = ref([]) as any; 

    const getJobClassifications =  async () => { 
      job_classification_options_list.value = [];
        const response = await getjobclassifications(); 
        if(response.data !== undefined && response.data.list !== undefined) {
          job_classification_options_list.value = response.data.list; 
        }
    }; 

    const httpGetCountries =  async () => { 
      countries.value = [];
        const response = await getcountries(); 
        if(response.data !== undefined && response.data.countries !== undefined) {
          countries.value = response.data.countries; 
        }
    }; 

    const getCities = async(country_id) => {  
        cities.value = [];
        const response = await getcities(country_id); 
        if(response.data !== undefined && response.data.cities !== undefined) {
            cities.value = response.data.cities; 
        }
    };

   

    const formData = ref<KTCreateApp>({   
      
      verifyingdata: false,
      country_text: "",
      country_text2: "",
      job_classification_text: "",
      province_text: "",
      responsedata: {},
      insured_firstName: "",
      insured_middleName: "",
      insured_lastName: "",
      insured_birthday: "",
      username: "",
      password: "",
      cpassword: "",
      sponsorid:  (props.slotdata !== undefined && props.slotdata.spid !== undefined) ? props.slotdata.spid : "",
      uplineid: (props.slotdata !== undefined && props.slotdata.upid !== undefined) ? props.slotdata.upid : "",
      position: (props.slotdata !== undefined && props.slotdata.position !== undefined) ? props.slotdata.position : "",
      insured_mobileNumber: "",
      insured_email: "",
      address_blockAndStreet: "",
      address_nameOfBuilding: "",
      address_unitNumber: "",
      address_city: "",
      address_postalCode: "",
      address_country: "",
      address_country2: "",
      job_classification: "",
      address_province: "",
      beneficiary_firstName: "",
      beneficiary_middleName: "",
      beneficiary_lastName: "",
      beneficiary_birthday: "",
      beneficiary_contact: "",
      beneficiary_relationship: "" ,
      userid: "",
      securitypin: "",
      istncchecked : false
    });



    const isDisabledUPID = props.slotdata !== undefined && props.slotdata.upid !== undefined;

 
    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );   

      console.log("mounted");
      if(props.slotdata !== undefined && props.slotdata.upid !== undefined) {
          formData.value.uplineid = props.slotdata.upid;
          formData.value.sponsorid = props.slotdata.spid;
          formData.value.position = props.slotdata.position;
      }
      httpGetCountries();
      getJobClassifications();
    });

    const createAppSchema = [
      Yup.object({}),  
      
      Yup.object({
        insured_firstName: Yup.string()
          .required()
          .max(50)
          .label("Insured First Name"), 
        insured_lastName: Yup.string()
          .required()
          .max(50)
          .label("Insured Last Name"),
        insured_birthday: Yup.string()
          .required()
          .label("Insured Birth Date"),
        username: Yup.string()
          .required()
          .label("Your Account Username"),
        password: Yup.string()
          .required()
          .label("Your Account Password"),
        cpassword: Yup.string()
          .required()
          .label("Your Account Password Confirmation"), 
        sponsorid: Yup.string()
          .required()
          .max(20)
          .label("Sponsor User ID"),
        uplineid: Yup.string()
          .required()
          .max(20)
          .label("Upline User ID"),
        position: Yup.string()
          .required()
          .label("Position")
      }), 

      Yup.object({  
        insured_mobileNumber: Yup.string()
          .required()
          .max(20)
          .label("Insured Mobile Number"),
        insured_email: Yup.string()
          .required()
          .email()
          .max(50)
          .label("Insured Email"),
          
        beneficiary_firstName: Yup.string()
          .required()
          .max(50)
          .label("Beneficiary First Name"),
        beneficiary_lastName: Yup.string()
          .required()
          .max(50)
          .label("Beneficiary Last Name"),
        beneficiary_birthday: Yup.string()
          .required()
          .label("Beneficiary Birth Date"),
        beneficiary_relationship: Yup.string()
          .required()
          .label("Beneficiary Relationship"),
        beneficiary_contact: Yup.string()
          .required()
          .max(20)
          .label("Beneficiary Contact#"),
        address_blockAndStreet: Yup.string()
          .required()
          .max(100)
          .label("Your Block and Street address"),
        address_country: Yup.string()
          .required()
          .label("Your Address Country"),
        address_country2: Yup.string()
          .required()
          .label("Your Country of Nationality"),
        job_classification:Yup.string()
          .required()
          .label("Job Classification"),
        address_province: Yup.string()
          .required()
          .label("Your Address Province"),
        address_postalCode:  Yup.string()
          .required()
          .label("Your Address Postal Code"),

      }),

      Yup.object({ 
        userid: Yup.string()
          .required()
          .max(20)
          .label("Activation User ID"),
        securitypin: Yup.string()
          .required()
          .max(20)
          .label("Activation PIN") 


      }) 
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3 | Step4>({
      validationSchema: currentSchema
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit(values => {
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }

      currentStepIndex.value++;
 

      if (!_stepperObj.value) {
        return;
      }

      console.log("CURRENT STEP : " + currentStepIndex.value);
      
      





      
      if(currentStepIndex.value === 3){ 
        validateForm(formData.value);
      }

      _stepperObj.value.goNext();
    });




    
const validateForm = async (data: any) => { 
      formData.value.verifyingdata = true;
      const response: any =   await confirm_register(data);

      formData.value.verifyingdata = false;
      formData.value.country_text = countries.value.find( e => {
          if(e.country_id == formData.value.address_country) {
           return   e.name;
          }
      }).name;

      formData.value.country_text2 = countries.value.find( e => {
          if(e.country_id == formData.value.address_country2) {
           return   e.name;
          }
      }).name;

      
      formData.value.job_classification_text = job_classification_options_list.value.find( e => {
          var jct_name = "";
          if(e.job_classification_id == formData.value.job_classification) {
             jct_name = e.name;
          }
          return jct_name;
      }).name;

      
      
      formData.value.province_text = cities.value.find( e => {
          if(e.zone_id == formData.value.address_province) {
           return   e.name;
          }
      }).name;

      if(response.data !== undefined && response.data.result === "FAILED") {  
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });  
           if (!_stepperObj.value) {
              return;
           }
           currentStepIndex.value = 0;
          _stepperObj.value.goFirst();
          return;
      }  
      
      if(response.data !== undefined && response.data.result === "SUCCESS") {
          formData.value.responsedata = response.data;
      }

      

    };

    const formSubmit = async () => {
       
      const response: any =   await save_register(formData.value);
      if(response.data !== undefined && response.data.result === "FAILED") {  
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });  
           if (!_stepperObj.value) {
              return;
           }
           currentStepIndex.value = 0;
          _stepperObj.value.goFirst();
          return;
      }  
      
      if(response.data !== undefined && response.data.result === "SUCCESS") {
              Swal.fire({
                text: response.data.message,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary"
                }
              }).then(() => {
                window.location.reload();
              });
      }

      
 
    };

    resetForm({
      values: {
        ...formData.value
      }
    });

    return {
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      formSubmit,
      currentStepIndex,
      formData,  
      countries,
      job_classification_options_list,
      getCities,
      cities,
      isDisabledUPID,

    };
  },
  methods: {
     
    async checkBoxValidator(event){
        if (event.target.checked) {
          this.formData.istncchecked = true;
        } else {
          this.formData.istncchecked = false;
        }

        console.log("this.formData.istncchecked : " + this.formData.istncchecked);
      },

    
    
  },
  mounted() { 
    //this.getcountries(); 
   // this.httpGetCountries();
  },
});
