
import { defineComponent, onMounted } from "vue";

import ListWidget5 from "@/components/widgets/lists/Widget5.vue"; 
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue"; 
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import AccountList from "@/components/dashboard/AccountList.vue"
import AccountDetails from "@/components/dashboard/AccountDetails.vue"
import {transactionhistory}  from "@/api/transaction";

import  SealProEncode from "@/views/modals/SealProEncode.vue"
import { getCurrentUser } from "@/utils/index"
import { getDashboardData } from "@/api/dashboard"
import { UserRole } from "../../utils/auth.roles";
import RegisterEndorserModalBibo from "@/views/auth/RegisterEndorserModalBibo.vue";

import RegisterAccountModalEC from "@/views/auth/RegisterModalEC.vue";
import RegisterModal from "@/views/auth/RegisterModal.vue";


    

export default defineComponent({
  name: "dashboarddtc",
  components: {
    ListWidget5,
    MixedWidget2,
    SealProEncode,
    AccountList,
    AccountDetails,
    RegisterEndorserModalBibo,
    RegisterAccountModalEC,
    RegisterModal
},
  data() {
    return {
      user: {},
      dashboarddata: {},
      allowClose: true,
      inputActivationDetail: false,
      isAddAccount: false,
      componentKey: 0,
      allowChangeName: true,
      encode_now_sealpro_key: 0,
      oviRankImage: "",
      firstCoverage: {},
      rankPrimeImage : "",
      showRankImage : true,
      currency : "",
      echequeUrl:"",
      pt : "",
      ccode : 0,
      slotdata : {},
      modalkey : 0,
      isShowR2MELink : false,
      r2meLink : "",
      r2meLinkLabel : ""
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  }, 
  methods: {
    async getDashboardData() {
      const response = await getDashboardData();
      
        if(response.data !== undefined ) {
          this.currency = response.data.currency;
          console.log(response.data);
          this.echequeUrl = response.data.echequeUrl;
          this.ccode = response.data.countryCode;
          if(this.currency == "PHP"){
            //this.showAdvisoryModal();
          }





        this.isShowR2MELink = response.data.isShowR2MELink;
        this.r2meLink = "https://shop.empoweredconsumerism.com/?ecpid=" + response.data.firstUsidInPlacement;
        this.r2meLinkLabel = "EC R2ME";

        }
    },
    async transactionhistory(){ 
        const response = await transactionhistory(1);

      },
      
    addAccount() {
        this.allowClose = true; 
        this.inputActivationDetail;
        this.isAddAccount = true;
        this.allowChangeName = false;

        setTimeout(() => {   
          const btn:any = this.$refs.addAccountModalButton;
          btn.click();
        }, 500);
        
    },
    initSlotData() {
      var user = String(localStorage.getItem("ec_current_usid"));

      this.slotdata = {
          upid : user,
          spid : user,
          position : "L"
        };
      
      this.modalkey += 1;

    },
    registerNewEndorser(){
      this.modalkey += 1;
			    setTimeout(() => {    
					const btn = this.$refs.registerNewBiboButton as any;
					btn.click();
				}, 100);
		  },

    registerNewECED(){
      this.modalkey += 1;
			    setTimeout(() => {    
					const btn:any  = this.$refs.registerNewECButton
;
					btn.click();
				}, 100);
		  },

    registerNewOVI(){
      this.modalkey += 1;
			    setTimeout(() => {    
					const btn:any = this.$refs.registerAccountOVIButton;
					btn.click();
				}, 100);
		  },
      upgradeAccount(){
        this.modalkey += 1;
			    setTimeout(() => {    
					const btn:any = this.$refs.addAccountOVIButton;
					btn.click();
				}, 100);
      }


  },
  mounted(){
    
        this.getDashboardData();
        this.user = getCurrentUser();
        this.pt = String(localStorage.getItem("pt"));
        this.initSlotData();

        if(localStorage.getItem("pt") === 'ovi'){
          this.$router.push('/dashboard/reminderspage') ;
        } else if(localStorage.getItem("pt") === 'ecp' || localStorage.getItem("pt") === 'edp'){
          this.$router.push('/dashboardecp/reminderspage') ;
        } 

  },
});
