
import { defineComponent, onMounted } from "vue";
 

export default defineComponent({
  name: "account-overview",
  components: { 
  },
    props: { 
        formdata: Object
  },
  setup() {
    onMounted(() => { 
    });
  }
});
